var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h2", {
    staticClass: "form-header",
    domProps: { textContent: _vm._s(_vm.title) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }