var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Form", {
    attrs: { title: _vm.schema.title, "fields-keys": _vm.fieldsKeys },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return _vm._l(_vm.schemaComponents, function(element) {
            return _c(element.component, {
              key: element.key,
              tag: "Component",
              attrs: {
                value: _vm.model[element.key],
                schema: element.props,
                required: _vm.schema.required,
                widgets: _vm.widgets,
                errors: _vm.errors[element.key]
              },
              on: { input: _vm.onValueChange }
            })
          })
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }